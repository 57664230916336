/**
 * @license
 * MyFonts Webfont Build ID 3924077, 2020-07-09T15:15:25-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Gilroy-Thin by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/thin/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-UltraLightItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/ultra-light-italic/
 * Copyright: Copyright &amp;#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-ThinItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/thin-italic/
 * Copyright: Copyright &amp;#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-SemiBoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold-italic/
 * Copyright: Copyright &amp;#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-SemiBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-UltraLight by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/ultra-light/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Regular by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-RegularItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular-italic/
 * Copyright: Copyright &amp;#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-MediumItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/medium-italic/
 * Copyright: Copyright &amp;#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Medium by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/medium/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-LightItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/light-italic/
 * Copyright: Copyright &amp;#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Light by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/light/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-HeavyItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/heavy-italic/
 * Copyright: Copyright &amp;#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Heavy by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/heavy/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-ExtraBoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/extra-bold-italic/
 * Copyright: Copyright &amp;#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-ExtraBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/extra-bold/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-BoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold-italic/
 * Copyright: Copyright &amp;#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Bold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-BlackItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/black-italic/
 * Copyright: Copyright &amp;#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Black by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/black/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * 
 * 
 * © 2020 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
/* @import url("//hello.myfonts.net/count/3be06d"); */

/* Ordered with assigned weights:
100 Thin
200 UltraLight
300 Light
400 Regular  ('normal')
500 Medium
600 SemiBold
700 Bold     ('bold')  
800 ExtraBold
900 Black
999 Heavy
*/

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-Thin/font.woff2") format("woff2"), url("webFonts/Gilroy-Thin/font.woff") format("woff");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-ThinItalic/font.woff2") format("woff2"),
    url("webFonts/Gilroy-ThinItalic/font.woff") format("woff");
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-UltraLight/font.woff2") format("woff2"),
    url("webFonts/Gilroy-UltraLight/font.woff") format("woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-UltraLightItalic/font.woff2") format("woff2"),
    url("webFonts/Gilroy-UltraLightItalic/font.woff") format("woff");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-Light/font.woff2") format("woff2"), url("webFonts/Gilroy-Light/font.woff") format("woff"), url("webFonts/Gilroy-Light/font.ttf") format("ttf");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-LightItalic/font.woff2") format("woff2"),
    url("webFonts/Gilroy-LightItalic/font.woff") format("woff");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-Regular/font.woff2") format("woff2"),
    url("webFonts/Gilroy-Regular/font.woff") format("woff"),
    url("webFonts/Gilroy-Regular/Gilroy-Regular.ttf") format("ttf");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-RegularItalic/font.woff2") format("woff2"),
    url("webFonts/Gilroy-RegularItalic/font.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-Medium/font.woff2") format("woff2"),
  url("webFonts/Gilroy-Medium/font.woff") format("woff"),
  url("webFonts/Gilroy-Medium/Gilroy-Medium.ttf") format("ttf");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-MediumItalic/font.woff2") format("woff2"),
    url("webFonts/Gilroy-MediumItalic/font.woff") format("woff");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-SemiBold/font.woff2") format("woff2"),
    url("webFonts/Gilroy-SemiBold/font.woff") format("woff"),
    url("webFonts/Gilroy-SemiBold/font.ttf") format("ttf");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-SemiBoldItalic/font.woff2") format("woff2"),
    url("webFonts/Gilroy-SemiBoldItalic/font.woff") format("woff");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-Bold/font.woff2") format("woff2"),
    url("webFonts/Gilroy-Bold/font.woff") format("woff"),
    url("webFonts/Gilroy-Bold/Gilroy-Bold.ttf") format("ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-BoldItalic/font.woff2") format("woff2"),
    url("webFonts/Gilroy-BoldItalic/font.woff") format("woff");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-ExtraBold/font.woff2") format("woff2"),
    url("webFonts/Gilroy-ExtraBold/font.woff") format("woff");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-ExtraBoldItalic/font.woff2") format("woff2"),
    url("webFonts/Gilroy-ExtraBoldItalic/font.woff") format("woff");
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-Black/font.woff2") format("woff2"), url("webFonts/Gilroy-Black/font.woff") format("woff");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-BlackItalic/font.woff2") format("woff2"),
    url("webFonts/Gilroy-BlackItalic/font.woff") format("woff");
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-Heavy/font.woff2") format("woff2"), url("webFonts/Gilroy-Heavy/font.woff") format("woff");
  font-style: normal;
  font-weight: 999;
}

@font-face {
  font-family: "Gilroy";
  src: url("webFonts/Gilroy-HeavyItalic/font.woff2") format("woff2"),
    url("webFonts/Gilroy-HeavyItalic/font.woff") format("woff");
  font-style: italic;
  font-weight: 999;
}
